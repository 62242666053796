import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { getCurrentUser, restoreToken } from './store/authSlice';
import HeartPort from './components/HeartPort';
import FullHearticlePage from './components/FullHearticlePage';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Signup from './components/Signup';
import ReviewPage from './components/ReviewPage';
import Profile from './components/Profile';
import { AppDispatch, RootState } from './store';
import ResetPassword from './components/PasswordResetComponent';
import PubMedArticlePage from './components/PubMedArticlePage';

const AppContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();  // Add the type here
  const [authLoading, setAuthLoading] = useState(true);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(restoreToken());
    setAuthLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div className="min-h-screen text-white bg-[#CAD4DD]">
      <Header />
      <Routes>
        <Route path="/" element={<HeartPort />} />
        <Route path="/rubric/:rubricId" element={<HeartPort />} />
        <Route path="/playlist/:playlistId" element={<HeartPort />} />
        <Route path="/hearticle/:id" element={<FullHearticlePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/review/translation/:id" element={<ReviewPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/pubmed/:pmid" element={<PubMedArticlePage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppContent;