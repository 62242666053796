import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from '../axios';
import { PubMedArticle, PubMedArticleTranslation, Topic, Rubric } from '../types';
import { RootState } from '../store';

const PubMedDisplay = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const [articles, setArticles] = useState<PubMedArticle[]>([]);
    const [loading, setLoading] = useState(true);
    const [featuredArticle, setFeaturedArticle] = useState<PubMedArticle | null>(null);
    const [sideArticles, setSideArticles] = useState<PubMedArticle[]>([]);
    const [gridArticles, setGridArticles] = useState<PubMedArticle[]>([]);
    const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

    const selectedRubric = useMemo(() => {
        const rubricId = searchParams.get('rubric');
        return rubricId ? Number(rubricId) : null;
    }, [searchParams]);

    useEffect(() => {
        // Force re-render when language changes
        setArticles([...articles]);
    }, [currentLanguage]);
    

    const fetchArticles = useCallback(async () => {
        setLoading(true);
        try {
            const endpoint = selectedRubric 
                ? `/api/pubmedarticles/rubric/${selectedRubric}/today`
                : '/api/pubmedarticles/top-by-rubric/today';
            const response = await axios.get<PubMedArticle[]>(endpoint);
            if (Array.isArray(response.data)) {
                // Set featured article (highest citation count or most recent)
                const sortedArticles = [...response.data].sort((a, b) => 
                    (b.citationCount || 0) - (a.citationCount || 0)
                );
                setFeaturedArticle(sortedArticles[0] || null);
                setSideArticles(sortedArticles.slice(1, 3));
                setGridArticles(sortedArticles.slice(3));
                setArticles(response.data);
            }
        } catch (error) {
            console.error('Error fetching PubMed articles:', error);
        } finally {
            setLoading(false);
        }
    }, [selectedRubric]);

    useEffect(() => {
        fetchArticles();
    }, [fetchArticles]);

    const handleRubricClick = useCallback((rubricId: number | undefined) => {
        if (rubricId) {
            const newRubricId = rubricId === selectedRubric ? null : rubricId;
            setSearchParams(
                newRubricId ? { rubric: newRubricId.toString() } : {},
                { replace: true }
            );
        }
    }, [selectedRubric, setSearchParams]);

    const handleReadFullStudy = useCallback((pmid: string) => {
        navigate(`/pubmed/${pmid}`);
    }, [navigate]);

    const getTranslation = useCallback((article: PubMedArticle): PubMedArticleTranslation | null => {
        if (!article.translations?.length) return null;
        return article.translations.find((t) => t.languageCode === currentLanguage) || article.translations[0];
    }, [currentLanguage]);

    const getImageUrl = (name: string | undefined, size: 'big' | 'small' = 'big') => {
        if (!name) return '';
        let imageName = name.toLowerCase()
            .replace(/ /g, '_')
            .replace(/ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/ß/g, 'ss')
            + '.png';
        imageName = size === 'big' ? 'big/' + imageName : imageName;
        return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
    };

    const uniqueRubrics = useMemo(() => {
        if (selectedRubric) {
            return articles
                .slice(0, 1)
                .map(article => ({
                    id: article.topic?.rubric?.id,
                    name: article.topic?.rubric?.name
                }))
                .filter((rubric): rubric is { id: number; name: string } => 
                    typeof rubric.id === 'number' && 
                    typeof rubric.name === 'string'
                );
        }
        return Array.from(new Set(
            articles
                .map(article => ({
                    id: article.topic?.rubric?.id,
                    name: article.topic?.rubric?.name
                }))
                .filter((rubric): rubric is { id: number; name: string } => 
                    typeof rubric.id === 'number' && 
                    typeof rubric.name === 'string'
                )
                .map(rubric => JSON.stringify(rubric))
        )).map(str => JSON.parse(str));
    }, [articles, selectedRubric]);

    if (loading) {
        return <div className="min-h-[400px] flex items-center justify-center">Loading...</div>;
    }

    if (!featuredArticle) {
        return null;
    }

    const featuredTranslation = getTranslation(featuredArticle);
    const isRecent = (article: PubMedArticle) => 
        new Date(article.publicationDate).getTime() > Date.now() - (30 * 24 * 60 * 60 * 1000);

    return (
        <div>
           
            {/* Featured and Side Articles Layout */}
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-[20px] mb-4 mt-16 xl:mt-48">
                {/* Featured Article */}
                {featuredArticle && (
          <div className="xl:col-span-2 bg-[#cde1ee] p-4 xl:p-6 relative overflow-visible min-h-[400px] xl:h-[600px] flex flex-col">
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-16 xl:-top-48">
              <img
                src={getImageUrl(featuredArticle.topic?.rubric?.name)}
                alt={featuredArticle.topic?.rubric?.name || ''}
                className="w-32 h-32 xl:w-96 xl:h-96 object-contain"
              />
            </div>

            <div className="mt-16 xl:mt-48 flex justify-between flex-1">
            
              <div className="w-full xl:w-2/3">
                <button 
                    onClick={() => featuredArticle.topic?.rubric?.id && 
                        handleRubricClick(featuredArticle.topic.rubric.id)}
                    className="px-2 py-1 rounded text-sm xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                >
                    {featuredArticle.topic?.rubric?.name || ''}
                </button>
                <h2 className="text-lg xl:text-2xl font-bold mb-2 text-[#111D4E] mt-4">
                {t('pubmed.featuredStudy')}
                </h2>
                <h3 className="text-xl xl:text-3xl font-bold text-[#49ABDB] line-clamp-3">
                    {featuredTranslation?.translatedTitle || featuredArticle.title}
                </h3>
                <p className="text-sm xl:text-lg text-[#111D4E] mt-4 italic">
                    {featuredArticle.keywords?.join(' • ')}
                </p>

                <button
                    onClick={() => handleReadFullStudy(featuredArticle.pmid)}
                    className="inline-flex items-center justify-center h-12 px-6 py-2 mt-6 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                >
                    {t('pubmed.readOriginal')}
                </button>
              </div>
            </div>
          </div>
        )}

                {/* Side Articles */}
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-[20px]">
                    {sideArticles.map((article) => {
                        const translation = getTranslation(article);
                        return (
                            <div key={article.pmid} className="bg-[#cde1ee] p-4 relative min-h-[200px] xl:h-[290px] flex flex-col overflow-hidden">
                                {article.citationCount === 0 && isRecent(article) && (
                                    <div className="absolute top-0 right-0 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white text-xs font-bold py-1 px-6 transform origin-top-right rotate-45 translate-y-8 translate-x-4">
                                        {t('pubmed.new')}
                                    </div>
                                )}
                                <div className="flex flex-1">
                                    <div className="flex-1">
                                        <button 
                                            onClick={() => article.topic?.rubric?.id && 
                                                handleRubricClick(article.topic.rubric.id)}
                                            className="px-2 py-1 rounded text-xs xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                                        >
                                            {article.topic?.rubric?.name || ''}
                                        </button>
                                        <h3 className="text-base xl:text-xl font-bold text-[#49ABDB] mt-2 line-clamp-2">
                                            {translation?.translatedTitle || article.title}
                                        </h3>
                                        <p className="text-xs xl:text-base text-[#111D4E] mt-2 line-clamp-2">
                                            {article.keywords?.join(' • ')}
                                        </p>
                                    </div>
                                    <img
                                        src={getImageUrl(article.topic?.rubric?.name, 'small')}
                                        alt={article.topic?.rubric?.name || ''}
                                        className="w-16 h-16 xl:w-32 xl:h-32 object-contain ml-4"
                                    />
                                </div>
                                <button
                                    onClick={() => handleReadFullStudy(article.pmid)}
                                    className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                                >
                                    {t('pubmed.readOriginal')}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Grid Articles */}
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {gridArticles.map((article) => {
                    const translation = getTranslation(article);
                    return (
                        <div key={article.pmid} className="bg-[#cde1ee] p-4 relative min-h-[200px] xl:h-[290px] flex flex-col overflow-hidden">
                            {article.citationCount === 0 && isRecent(article) && (
                                <div className="absolute top-0 right-0 bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] text-white text-xs font-bold py-1 px-6 transform origin-top-right rotate-45 translate-y-8 translate-x-4">
                                    {t('pubmed.new')}
                                </div>
                            )}
                            <div className="flex flex-1">
                                <div className="flex-1">
                                    <button 
                                        onClick={() => article.topic?.rubric?.id && 
                                            handleRubricClick(article.topic.rubric.id)}
                                        className="px-2 py-1 rounded text-xs xl:text-base bg-[#003C72] text-white hover:bg-[#002a52] transition-colors"
                                    >
                                        {article.topic?.rubric?.name || ''}
                                    </button>
                                    <h3 className="text-base xl:text-xl font-bold text-[#49ABDB] mt-2 line-clamp-2">
                                        {translation?.translatedTitle || article.title}
                                    </h3>
                                    <p className="text-xs xl:text-base text-[#111D4E] mt-2 line-clamp-2">
                                        {article.keywords?.join(' • ')}
                                    </p>
                                </div>
                                <img
                                    src={getImageUrl(article.topic?.rubric?.name, 'small')}
                                    alt={article.topic?.rubric?.name || ''}
                                    className="w-16 h-16 sm:w-20 sm:h-20 object-contain ml-4"
                                />
                            </div>
                            <button
                                onClick={() => handleReadFullStudy(article.pmid)}
                                className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                            >
                                {t('pubmed.readOriginal')}
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PubMedDisplay;