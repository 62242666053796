import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PubMedArticle } from '../types';
import axios from '../axios';
import logo from '../assets/images/logo_with_text.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';

const PubMedArticlePage: React.FC = () => {
  const { pmid } = useParams<{ pmid: string }>();
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [article, setArticle] = useState<PubMedArticle | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/api/pubmedarticles/public/${pmid}`);
        setArticle(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch article');
        setLoading(false);
      }
    };

    fetchArticle();
  }, [pmid]);

  const getCurrentTranslation = () => {
    if (!article) return null;
    return article.translations.find(t => t.languageCode === currentLanguage) || null;
  };

  if (loading) return <div>Loading...</div>;
  if (error || !article) return <div>Error: {error || t('article.notFound')}</div>;

  const translation = getCurrentTranslation();
  const abstractText = translation ? translation.translatedAbstract : article.abstractText;
  const title = translation ? translation.translatedTitle : article.title;

  const InfoSection = ({ title, content }: { title: string; content: string | number | string[] }) => {
    if (content === 0) {
        return (
            <div className="text-white mb-4">
              <h4 className="font-bold">{title}</h4>
              <p>No Data</p>
            </div>
          );
    }
    if (!content || (Array.isArray(content) && content.length === 0)) return null;
    return (
      <div className="text-white mb-4">
        <h4 className="font-bold">{title}</h4>
        <p>{Array.isArray(content) ? content.join(', ') : content}</p>
      </div>
    );
  };

  return (
    <div className="mx-auto md:p-4 mx-0 lg:mx-4 font-sans bg-white">
      <header className="flex justify-between p-4 lg:p-0 items-center lg:mb-4">
        <div className="flex items-center">
          <img src={logo} alt="HeartPort logo" className="mr-2 w-auto h-14 md:w-auto md:h-auto" />
        </div>
      </header>

      <div className="bg-[#CAD4DD] p-4 relative z-0">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-2/3 pr-0 md:pr-4 mb-4 md:mb-0 md:mx-6">
            <h1 className="text-2xl font-bold text-[#49ABDB] mb-4">{title}</h1>
            <p className="text-l text-justify text-black mb-4 whitespace-pre-wrap">
              {abstractText}
            </p>
          </div>

          <div className="w-full md:w-1/3 md:mx-4">
            <div className="bg-gradient-to-r from-[#003C72] to-[#49ABDB] p-4">
              <h3 className="text-2xl font-bold mb-4 text-white">Study Information</h3>
              
              <InfoSection title="Authors" content={article.authors} />
              <InfoSection title="Journal" content={article.journal} />
              <InfoSection title="Publication Date" content={new Date(article.publicationDate).toLocaleDateString()} />
              <InfoSection title="Citations" content={article.citationCount || 0} />
              {article.reputabilityScore && (
                <InfoSection 
                  title="Publication Merit Score" 
                  content={`${(article.reputabilityScore * 12.5).toFixed(2)}%`} 
                />
              )}
              <InfoSection title="Keywords" content={article.keywords} />
              <InfoSection title="Publication Types" content={article.publicationTypes} />

              {article.url && (
                <a 
                  href={article.url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-block mt-4 bg-white text-[#49ABDB] px-4 py-2 rounded font-bold hover:bg-opacity-90"
                >
                  View on PubMed
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PubMedArticlePage;