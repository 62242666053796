import React from 'react';
import { Link } from 'react-router-dom';
import PlayButton from './PlayButton';
import { Hearticle, HearticleTranslation } from '../types';
import { useTranslation } from 'react-i18next';

interface ArticleCardProps {
  article: Hearticle;
  translation: HearticleTranslation;
  imageSize?: 'sm' | 'md' | 'lg';
  className?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  article,
  translation,
  imageSize = 'md',
  className = '',
}) => {
  const { t } = useTranslation();

  const imageSizes = {
    sm: 'w-12 h-12 sm:w-16 sm:h-16',
    md: 'w-16 h-16 sm:w-20 sm:h-20',
    lg: 'w-20 h-20 sm:w-24 sm:h-24 xl:w-32 xl:h-32'
  };

  const getImageUrl = (name: string | undefined,) => {
    if (!name) return '';
    let imageName = name.toLowerCase()
        .replace(/ /g, '_')
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/ß/g, 'ss')
        + '.png';
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
};

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    //target.src = `${process.env.REACT_APP_API_URL}/uploads/topic_images/nutrition_medicine.png`;
    target.onerror = null;
  };

  return (
    <div className={`bg-[#CAD4DD] p-4 relative min-h-[250px] sm:min-h-[280px] flex flex-col ${className}`}>
      <img
        src={getImageUrl(article.topic.name)}
        alt={article.topic.name}
        onError={handleImageError}
        className={`absolute top-3 right-3 object-contain ${imageSizes[imageSize]}`}
      />

      <div className="pr-20 space-y-3 flex-1">
        <div
          className="px-2 py-1 inline-block rounded text-xs sm:text-sm line-clamp-1"
          style={{
            backgroundColor: article.topic.color,
            color: '#000000',
          }}
        >
          {article.topic.name}
        </div>
        
        <h3 className="text-base sm:text-lg xl:text-xl font-bold text-[#49ABDB] line-clamp-2">
          {translation?.headline || t('noHeadlineAvailable')}
        </h3>
        
        <p className="text-xs sm:text-sm xl:text-base text-black line-clamp-2 italic">
          {translation?.keywords?.join(' - ') || t('noKeywordsAvailable')}
        </p>
      </div>
      
        <div className="flex items-center gap-2">
            <Link
                to={`/hearticle/${article.id}`}
                className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
            >
                {t('heartport.readFullHearticle')}
            </Link>
            <div className="mt-2">
                <PlayButton
                hearticleId={article.id}
                translationId={translation.id}
                />
            </div>
        </div>
    </div>
  );
};

export default ArticleCard;