import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { Hearticle, HearticleTranslation, Review } from '../types';
import { RootState } from '../store';
import ArticleCard from './ArticleCard';

const HearticleDisplay = () => {
  const { t } = useTranslation();
  const [dailyHearticles, setDailyHearticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState(true);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [reviewedTranslations, setReviewedTranslations] = useState<{ [translationId: number]: boolean }>({});

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      try {
        const targetDate = new Date(Date.now() - 86400000).toISOString().split('T')[0];
        const response = await axios.get<Hearticle[]>('/api/public/hearticles', {
          params: { publicationDate: targetDate },
        });
        setDailyHearticles(response.data);
      } catch (error) {
        console.error('Error fetching content:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    const fetchReviewsForTranslations = async (translations: HearticleTranslation[]) => {
      const translationReviewPromises = translations.map(async (translation) => {
        try {
          const response = await axios.get<Review[]>(`/api/review/translation/${translation.id}`);
          const reviews = response.data;
          const isReviewed = reviews.some((review) => review.status === 'APPROVED');
          return { translationId: translation.id, isReviewed };
        } catch (error) {
          console.error(`Error fetching reviews for translation ${translation.id}:`, error);
          return { translationId: translation.id, isReviewed: false };
        }
      });

      const results = await Promise.all(translationReviewPromises);
      const reviewedMap = results.reduce((acc, curr) => {
        acc[curr.translationId] = curr.isReviewed;
        return acc;
      }, {} as { [translationId: number]: boolean });
      setReviewedTranslations((prev) => ({ ...prev, ...reviewedMap }));
    };

    const allTranslations = dailyHearticles.map(hearticle => 
      getTranslation(hearticle)
    );

    if (allTranslations.length > 0) {
      fetchReviewsForTranslations(allTranslations);
    }
  }, [dailyHearticles, currentLanguage]);

  const getTranslation = (hearticle: Hearticle): HearticleTranslation => {
    return hearticle.translations.find((t) => t.languageCode === currentLanguage) ||
      hearticle.translations[0];
  };

  if (loading) {
    return <p className="text-center">{t('loading')}</p>;
  }

  return (
    <div className="mx-0 lg:mx-4 my-8 bg-white pb-8">
      <p className="text-2xl font-bold text-[#111D4E] p-4">{t('heartport.todaysHearticle')}</p>
      <h2 className="text-4xl font-bold mb-6 text-[#47ABDB] p-4">{t('heartport.latestContent')}</h2>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        {dailyHearticles.map((article) => {
          const translation = getTranslation(article);
          const isReviewed = reviewedTranslations[translation.id];
          
          return (
            <ArticleCard
              key={article.id}
              article={article}
              translation={translation}
              className="min-h-[200px] xl:h-[280px]"
            />
          );
        })}
      </div>
    </div>
  );
};

export default HearticleDisplay;