import React, { useState } from 'react';
import { Trash2, X } from 'lucide-react';
import axios from '../axios';
import { useNavigate } from 'react-router-dom';

interface DeleteHearticleButtonProps {
  hearticleId: number;
  headline: string;
}

const DeleteHearticleButton: React.FC<DeleteHearticleButtonProps> = ({ hearticleId, headline }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async (): Promise<void> => {
    try {
      setIsDeleting(true);
      await axios.delete(`/api/hearticle/${hearticleId}`);
      navigate('/');
    } catch (error) {
      console.error('Failed to delete hearticle:', error);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="ml-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center disabled:opacity-50"
        disabled={isDeleting}
        type="button"
      >
        <Trash2 className="w-4 h-4 mr-2" />
        Delete
      </button>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-xl font-bold">Delete Hearticle?</h2>
              <button 
                onClick={() => setShowModal(false)}
                className="text-gray-500 hover:text-gray-700"
                type="button"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <p className="mb-4 text-gray-600">
              This action cannot be undone. This will permanently delete the hearticle:
            </p>
            <p className="mb-6 font-medium">{headline}</p>
            
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowModal(false)}
                className="text-black px-4 py-2 border border-gray-300 rounded hover:bg-gray-100"
                type="button"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isDeleting}
                type="button"
              >
                {isDeleting ? 'Deleting...' : 'Yes, delete hearticle'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteHearticleButton;