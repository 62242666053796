export interface PaginatedResponse<T> {
  content: T[];
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  last: boolean;
  first: boolean;
}

// Base interaction interface that matches the AbstractInteraction from backend
export interface AbstractInteraction {
  id?: number;
  hearticle: {
      id: number;
  };
  interactionType: InteractionType;
  interactionDate: string;
  sharePlatform?: SharePlatform;
  timeSpentSeconds?: number;
}

// Specific interaction types extending the base
export interface UserHearticleInteraction extends AbstractInteraction {
  user: {
      id: number;
      username: string;
  };
}

export interface AnonymousInteraction extends AbstractInteraction {
  sessionId: string;
  visitorId?: string;
}

// DTO for creating/sending interactions
export interface InteractionDTO {
  hearticleId: number;
  interactionType: InteractionType;
  sharePlatform?: SharePlatform;
  timeSpentSeconds?: number;
}

// Statistics interfaces
export interface InteractionStatistics {
  totalInteractions: number;
  interactionsByType: {
      [key in InteractionType]: number;
  };
  sharesByPlatform: {
      [key in SharePlatform]: number;
  };
  avgTimeSpentSeconds: number;
}

export enum InteractionType {
  READ = 'READ',
  LIKE = 'LIKE',
  BOOKMARK = 'BOOKMARK',
  SHARE = 'SHARE',
  COMMENT = 'COMMENT',
  FOLLOW_TOPIC = 'FOLLOW_TOPIC',
  PRINT = 'PRINT',
  DOWNLOAD = 'DOWNLOAD',
  VIEW_RELATED = 'VIEW_RELATED',
  REPORT = 'REPORT'
}

export enum SharePlatform {
  FACEBOOK = 'FACEBOOK',
  LINKEDIN = 'LINKEDIN',
  X = 'X',
  INSTAGRAM = 'INSTAGRAM',
  WHATSAPP = 'WHATSAPP',
  EMAIL = 'EMAIL'
}

// Rest of your existing interfaces remain the same
export interface HearticleTranslation {
  id: number;
  languageCode: string;
  headline: string;
  keywords: string[];
  mainTextBody: string;
  mainActions: string[];
  harmfulActions: string[];
  hearticle?: Hearticle;
}

export interface PubMedArticleTranslation {
  id: number;
  languageCode: string;
  translatedTitle: string;
  translatedAbstract: string;
}

export interface Rubric {
  id: number;
  name: string;
  description: string;
}

export interface PubMedArticle {
  pmid: string;
  title: string;
  abstractText: string;
  url: string;
  authors: string[];
  publicationDate: string;
  displayDate: string;
  displayed: boolean;
  citationCount: number;
  journal: string;
  keywords: string[];
  reputabilityScore: number;
  impactFactor: number | null;
  publicationTypes: string[];
  translations: PubMedArticleTranslation[];
  topic?: Topic;  // Hidden from user
}

export interface Topic {
  id: number;
  name: string;
  imagePath: string;
  additionalInfo: string;
  statusCode: 'ACTIVE' | 'INACTIVE' | 'ARCHIVED';
  subTopics: string[];
  color: string;
  rubric?: Rubric
}

export interface Rubric {
  id: number;
  name: string;
}

export interface Hearticle {
  id: number;
  creationDate: string;
  publicationDate: string;
  status: 'DRAFT' | 'PUBLISHED' | 'ARCHIVED';
  searchPhrases: string[];
  relatedPubMedArticles: PubMedArticle[];
  translations: HearticleTranslation[];
  topic: HearticleTopic;
}

export interface HearticleTopic {
  id: number;
  name: string;
  imagePath: string;
  additionalInfo: string;
  statusCode: 'ACTIVE' | 'INACTIVE' | 'ARCHIVED';
  subTopics: string[];
  color: string;
}

export interface Review {
  id: number;
  hearticleTranslation: HearticleTranslation;
  reviewer: {
      id: string;
      username?: string;
      email?: string;
  };
  reviewDateTime: string;
  comments: string;
  status: ReviewStatus;
  changes: ReviewChange[];
}

export type ReviewStatus = 'PENDING' | 'APPROVED' | 'REJECTED' | 'NEEDS_REVISION';

export type ChangeField = 'HEADLINE' | 'KEYWORD' | 'MAIN_TEXT_BODY' | 'MAIN_ACTION';

export interface ReviewChange {
  id?: number;
  field: ChangeField;
  previousValue: string;
  newValue: string;
  listIndex?: number;
}