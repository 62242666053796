import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PubMedArticle, PubMedArticleTranslation } from '../types';
import { RootState } from '../store';
import studyImage from '../assets/images/study.png';
import header from '../assets/videos/header.mp4';

import { Button } from './Button';

interface RelatedStudiesProps {
  studies: PubMedArticle[];
}

const RelatedStudies: React.FC<RelatedStudiesProps> = ({ studies }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState<string | null>(null);
  const { t } = useTranslation();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  const safeStudies = Array.isArray(studies) ? studies.filter(Boolean) : [];
  const displayedStudies = expanded ? safeStudies : safeStudies.slice(0, Math.min(3, safeStudies.length));
  const showViewMoreButton = !expanded && safeStudies.length > 3;

  const getTranslation = (study: PubMedArticle): PubMedArticleTranslation | undefined => {
    if (!study?.translations?.length) return undefined;
    const translation = study.translations.find(t => t?.languageCode === currentLanguage);
    return translation || study.translations[0];
  };

  const getContent = (study: PubMedArticle) => {
    const translation = getTranslation(study);
    return {
      title: translation?.translatedTitle || study?.title || t('common.titleNotAvailable'),
      abstract: translation?.translatedAbstract || study?.abstractText || t('common.abstractNotAvailable'),
    };
  };

  const rows = displayedStudies.reduce((acc: PubMedArticle[][], study, index) => {
    const rowIndex = Math.floor(index / 4);
    if (!acc[rowIndex]) {
      acc[rowIndex] = [];
    }
    acc[rowIndex].push(study);
    return acc;
  }, []);

  const handleViewMore = () => setExpanded(true);
  const handleStudyClick = (pmid: string) => setSelectedStudy(selectedStudy === pmid ? null : pmid);
  const getAuthors = (study: PubMedArticle) => {
    return study?.authors?.filter(Boolean)?.join(', ') || t('common.authorsNotAvailable');
  };

  if (!safeStudies.length) return null;

  const renderStudyExpandedMobile = (study: PubMedArticle, isRightColumn = false) => {
    const content = getContent(study);
    return (
      <div 
        className={`w-[calc(100vw-1rem)] bg-[#CDE1EE] p-6 mt-0 mb-6 ${isRightColumn ? '-ml-[calc(100%+1.5rem)]' : '-ml-2'}`} 
        style={{ color: '#111D4E' }}
      >
        <h4 className="text-xl font-bold mb-4 text-[#49ABDB]">
          {content.title}
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h5 className="font-semibold mb-2">{t('studies.abstractLabel')}</h5>
            <p className="text-sm leading-relaxed">{content.abstract}</p>
          </div>
          <div className="space-y-4">
            <div>
              <h5 className="font-semibold mb-2">{t('studies.authorsLabel')}</h5>
              <p className="text-sm">{getAuthors(study)}</p>
            </div>
            <div>
              <h5 className="font-semibold mb-2">{t('studies.journalLabel')}</h5>
              <p className="text-sm">{study.journal || t('common.journalNotAvailable')}</p>
            </div>
          </div>
        </div>
        {study.url && (
          <div className="mt-6">
            <a 
              href={study.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary">
                {t('studies.viewOnPubMedButton')}
              </Button>
            </a>
          </div>
        )}
      </div>
    );
  };

  const renderStudyExpandedDesktop = (study: PubMedArticle) => {
    const content = getContent(study);
    return (
      <div className="w-full bg-[#CDE1EE] p-6 mt-0 mb-6" style={{ color: '#111D4E' }}>
        <h4 className="text-xl font-bold mb-4 text-[#49ABDB]">
          {content.title}
        </h4>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h5 className="font-semibold mb-2">{t('studies.abstractLabel')}</h5>
            <p className="text-sm leading-relaxed">{content.abstract}</p>
          </div>
          <div className="space-y-4">
            <div>
              <h5 className="font-semibold mb-2">{t('studies.authorsLabel')}</h5>
              <p className="text-sm">{getAuthors(study)}</p>
            </div>
            <div>
              <h5 className="font-semibold mb-2">{t('studies.journalLabel')}</h5>
              <p className="text-sm">{study.journal || t('common.journalNotAvailable')}</p>
            </div>
          </div>
        </div>
        {study.url && (
          <div className="mt-6">
            <a 
              href={study.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="primary">
                {t('studies.viewOnPubMedButton')}
              </Button>
            </a>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mt-4 md:mt-8 md:mx-6">
      <h3 className="text-xl md:text-4xl font-bold mb-4 md:mb-6" style={{ color: '#49ABDB' }}>
        {t('studies.viewReferencesTitle')}
      </h3>
      
      {/* Mobile Layout */}
      <div className="md:hidden">
      <div className="flex flex-wrap -mx-2">
          {displayedStudies.map((study, index) => (
            <React.Fragment key={study?.pmid || `study-${index}`}>
              <div className="w-1/2 px-2 mb-4">
                <button 
                  onClick={() => study?.pmid && handleStudyClick(study.pmid)}
                  className="w-full text-left focus:outline-none relative"
                >
                  <div className="flex flex-col items-center relative">
                    {selectedStudy === study?.pmid && (
                      <div className="absolute inset-0 bg-[#CDE1EE] z-0" style={{
                        bottom: '-2rem',
                      }} />
                    )}
                    <div className="relative w-full pb-[100%] overflow-hidden transition-all duration-200 z-10">
                      <div className="absolute inset-0 flex items-center justify-center">
                        <img
                          src={studyImage || ""}
                          alt={t('studies.studyImageAlt')}
                          className="w-4/5 h-4/5 object-contain bg-transparent"
                        />
                      </div>
                    </div>
                    <p className="text-xs text-center h-20 overflow-hidden relative z-10" 
                       style={{ color: '#111D4E' }}>
                      {getContent(study).title}
                    </p>
                  </div>
                </button>
                {selectedStudy === study?.pmid && (
                  <div className="relative">
                    {renderStudyExpandedMobile(study, index % 2 === 1)}
                  </div>
                )}
 </div>
            </React.Fragment>
          ))}
          {showViewMoreButton && (
            <div className="w-full px-2 mb-4">
              <Button 
                onClick={handleViewMore}
                variant="primary"
                className="w-full h-28 text-lg font-bold"  >
                {t('studies.viewMoreButton')}
              </Button>
              </div>
          )}
        </div>
        </div>

      {/* Desktop Layout */}
      <div className="hidden md:block">
  {rows.map((row, rowIndex) => (
    <div key={rowIndex} className="mb-4 relative">
      <div className="flex flex-wrap -mx-4">
        {row.map((study) => (
          <div key={study?.pmid || `study-${rowIndex}`} className="w-1/4 px-4 mb-6">
            <button 
              onClick={() => study?.pmid && handleStudyClick(study.pmid)}
              className="w-full text-left focus:outline-none relative"
            >
              <div className="flex flex-col items-center relative">
                {selectedStudy === study?.pmid && (
                  <div className="absolute inset-0 bg-[#CDE1EE] z-0" style={{ bottom: '-2rem' }} />
                )}
                <div 
                  className="relative w-4/5 pb-[80%] overflow-hidden transition-all duration-200 z-10"
                  onMouseEnter={(e) => e.currentTarget.querySelector('video')?.play()}
                  onMouseLeave={(e) => {
                    const video = e.currentTarget.querySelector('video');
                    if (selectedStudy !== study?.pmid) video?.pause();
                  }}
                >
                  <div className="absolute inset-0 flex items-center justify-center">
                    <video
                      src={header} 
                      muted 
                      loop 
                      playsInline
                      className="w-4/5 h-4/5 object-contain bg-transparent rounded-full"
                      style={{
                        borderRadius: '50%', // Makes the video round
                        overflow: 'hidden',  // Ensures content stays within the rounded area
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0)', // Optional, for clean edges
                      }}
                      ref={(videoElement) => {
                        if (videoElement) {
                          // Pause video if it’s not selected and not hovered
                          if (selectedStudy !== study?.pmid) videoElement.pause();
                        }
                      }}
                    />
                  </div>
                </div>
                <p 
                  className="text-sm text-center h-16 overflow-hidden relative z-10" 
                  style={{ color: '#111D4E' }}
                >
                  {getContent(study).title}
                </p>
              </div>
            </button>
          </div>
        ))}
        {rowIndex === 0 && showViewMoreButton && (
          <div className="w-1/4 px-4 mb-6 flex items-center justify-center">
            <Button 
              onClick={handleViewMore}
              variant="primary"
              className="w-full h-28 text-4xl font-bold"
            >
              {t('studies.viewMoreButton')}
            </Button>
          </div>
        )}
      </div>
      {row.some(study => study?.pmid === selectedStudy) && (
        <div className="w-full">
          {row.map(study => study?.pmid === selectedStudy && renderStudyExpandedDesktop(study))}
        </div>
      )}
    </div>
  ))}
</div>

    </div>
  );
};

export default RelatedStudies;