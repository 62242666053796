import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocalizedContent } from '../hooks/useLocalizedContent';
import logo from '../assets/images/logo512.png';
import bottom from '../assets/images/bottom.png';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { content: emailContent } = useLocalizedContent('footer.email');

  const handleMailClick = () => {
    window.location.href = 'mailto:crew@heartport.org';
  };

  const getPrivacyPolicyLink = () => {
    const lang = t('language') === 'de' ? 'de' : 'en';
    return `${process.env.REACT_APP_API_URL || ''}/uploads/privacy_policy_${lang}.pdf`;
  };

  const getTandCLink = () => {
    return `${process.env.REACT_APP_API_URL || ''}/uploads/agb.pdf`;
  };

  return (
    <footer className="relative flex flex-col items-center overflow-hidden text-center">
      <div className="mb-4 flex w-full flex-col">
        <div className="hidden md:flex w-full justify-between">
          <h2 className="m-0 w-1/2 pr-[10px] text-right text-[8rem] text-white">
            GET IN
          </h2>
          <h2 className="m-0 w-1/2 pl-[10px] text-left text-[8rem] text-white"></h2>
        </div>
        <div className="hidden md:flex w-full justify-between">
          <h2 className="m-0 w-1/2 pr-[10px] text-right text-[8rem] text-white"></h2>
          <h2 className="m-0 w-1/2 pl-[10px] text-left text-[8rem] text-white">
            <span
              className="text-transparent"
              style={{
                WebkitTextStroke: '1px white',
                WebkitTextFillColor: 'transparent',
              }}
            >
              TOUCH
            </span>
          </h2>
        </div>
        {/* Mobile version */}
        <div className="flex md:hidden flex-col items-center">
          <h2 className="m-0 text-[4rem] text-white">GET IN</h2>
          <h2 className="m-0 text-[4rem] text-white">
            <span
              className="text-transparent"
              style={{
                WebkitTextStroke: '1px white',
                WebkitTextFillColor: 'transparent',
              }}
            >
              TOUCH
            </span>
          </h2>
        </div>
      </div>
      <div
        className="mb-[40px] md:mb-[80px] ml-[-20%] h-[256px] w-[256px] md:h-[512px] md:w-[512px] bg-center bg-no-repeat bg-contain"
        style={{ backgroundImage: `url(${logo})` }}
      ></div>
      <a
        href="mailto:crew@heartport.org"
        className="mb-[25px] md:mb-[50px] text-[2rem] md:text-[3rem] text-[#48ABDB] no-underline"
      >
        {emailContent || 'crew@heartport.org'}
      </a>
      <div className="text-white text-sm md:text-base">{t('footer.contactRequest')}</div>
      <button
        onClick={handleMailClick}
        className="mt-[10px] md:mt-[20px] mb-3 md:mb-6 h-[80px] w-[80px] md:h-[120px] md:w-[120px] cursor-pointer rounded-full border-[2px] md:border-[3px] border-[#4a90e2] bg-transparent px-2 md:px-4 py-1 md:py-2 text-[1.2rem] md:text-[1.8rem] font-bold text-[#48ABDB]"
      >
        MAIL
      </button>
      <div className="mt-2 md:mt-4 flex flex-col items-center gap-2 md:flex-row md:justify-center md:gap-4 mb-[155px] md:mb-[311px]">
        <Link
          to={getPrivacyPolicyLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.privacyPolicy')}
        </Link>
        <Link
          to={getTandCLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.agb')}
        </Link>
        <Link
          to="/impressum"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.impressum')}
        </Link>
      </div>
      <div
        className="absolute bottom-0 left-0 h-[155px] md:h-[311px] w-full bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${bottom})` }}
      ></div>
    </footer>
  );
};

export default Footer;